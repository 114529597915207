import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { calculateRating } from '../components/helper';
import Img from 'gatsby-image';
import '../assets/styles/pages/quote/detail.scss';
import MapIcon from '../images/quote-detail/map-icon.inline.svg';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SelectionDate from '../components/quotes/selection-date';

const QuoteIndexPage = () => {
  const [isSticky, setSticky] = useState(false);
  const [showSelectDateModel, setSelectDateModel] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      quoteImage: file(relativePath: { eq: "quote-beater-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const handleScroll = (top) => {
    setSticky(window.pageYOffset > top);
  };

  useEffect(() => {
    if (window.innerWidth > 767) {
      const $jobTitle = document.getElementsByClassName('job-details')[0];
      const top = $jobTitle.offsetTop;
      window.addEventListener('scroll', () => handleScroll(top));

      return () => {
        window.removeEventListener('scroll', () => handleScroll(top));
      };
    }
  }, []);

  return (
    <Layout wrapperClass="quote-selection-container quote-detail-container" hideHeaderFooter={true}>
      <SEO title="Quote Selection" />
      <div className="quote-detail-wrapper">
        <div className="title-wrapper">
          <div className="container">
            <div className="location-details">
              <div className="overview">
                <div className="overview-image">
                  <Img fluid={data.quoteImage.childImageSharp.fluid} />
                </div>
                <div className="overview-details">
                  <h1>Camperdown Collision Centre</h1>
                  <div className="rating-wrapper">
                    <span className="rating-number">{calculateRating(3)}</span>
                    <div className="rating-stars-container">
                      <span className="rating-stars rating-stars-off">★★★★★</span>
                      <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(3)}%` }}>★★★★★</span>
                    </div>
                    <span className="rating-count">(12)</span>
                  </div>
                  <span className="location">Camperdown - 1.45 km away</span>
                  <span className="job-complete has-icon"><MapIcon />30+ Jobs Completed</span>
                </div>
              </div>
              <div className="servces detail-section">
                <h3>Servieces</h3>
                <ul>
                  <li>
                    1.45 km away
                  </li>
                  <li>
                    Lifetime warranty on all work
                  </li>
                  <li>
                    Free external car wash
                  </li>
                  <li>
                    Contactless repair (key drop off or drop box)
                  </li>
                </ul>
              </div>
              <div className="work-overview detail-section">
                <h3>Overview of Work</h3>
                <p>
                  Repair damaged area and repaint to match surrounding panels. No parts required.
                </p>
              </div>
              <div className="hygiene detail-section">
                <h3>Repair Hygiene Practices</h3>
                <ul>
                  <li>
                    Disinfect, sanitisation and wipe down of vehicle before return (mandatory)
                  </li>
                  <li>
                    Vehicle cleaning and wash
                  </li>
                  <li>
                    Staff sanitisation before and after repairs
                  </li>
                  <li>
                    Social distancing of all repair workers
                  </li>
                </ul>
              </div>
            </div>
            <div className={`job-details${isSticky ? ' sticky' : ''}`}>
              <div className="job-detail-section">
                <h3>Available from</h3>
                <span>Tue 14th Oct</span>
              </div>
              <div className="job-detail-section">
                <h3>Repair Time</h3>
                <span>3 Days</span>
              </div>
              <div className="job-detail-section-cost">
                <h3>Total Cost</h3>
                <span>DingGo guarantees this price</span>
                <span className="cost">$865</span>
                <span className="avails">Available Mon 14th Oct</span>
              </div>
              <span className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectDateModel(!showSelectDateModel);
                }}>Select Date</span>
            </div>
          </div>
        </div>
        <div className="business-details-wrapper">
          <div className="container">
            <div className="busness-details">
              <h2>Business Details</h2>
              <div className="detail-section">
                <h3>Qualifications</h3>
                <p className="has-icon">
                  <MapIcon />115 Denison St Camperdown, Sydney, NSW 2050
                </p>
                <p className="has-icon">
                  <MapIcon />ABN: 26 120 709 748
                </p>
                <p className="has-icon">
                  <MapIcon />MVRL: 49402
                </p>
              </div>
              <div className="detail-section">
                <h3>Qualifications</h3>
                <p className="has-icon">
                  <MapIcon />NSW Fair Trading
                </p>
                <p className="has-icon">
                  <MapIcon />Lifetime warranty on all work
                </p>
              </div>
              <div className="detail-section">
                <h3>Badges</h3>
                <p className="has-icon">
                  <MapIcon />Verified by DingGo
                </p>
              </div>
              <div className="detail-section">
                <h3>About</h3>
                <p>
                  Camperdown Collision Centre is an established business that offers smash repair services to customers all over Sydney and beyond. We enjoy a convenient location that allows us to pick damaged cars and deliver them to our clients quite easily.
                </p>
              </div>
              <div className="detail-section photo-gallery-wrapper">
                <h3>Photo Gallery</h3>
                <div className="photo-gallery">
                  <img src="https://via.placeholder.com/335x180" />
                  <img src="https://via.placeholder.com/335x180" />
                  <img src="https://via.placeholder.com/335x180" />
                  <img src="https://via.placeholder.com/335x180" />
                </div>
              </div>
              <div className="detail-section">
                <h3>Shop Services</h3>
                <ul>
                  <li>
                    Accepts Visa/MasterCard & EFTPOS
                  </li>
                  <li>
                    Lifetime Warranty on all work
                  </li>
                  <li>
                    Waiting room with Tea & Coffee
                  </li>
                  <li>
                    Works with most major insurance companies
                  </li>
                </ul>
              </div>
              <div className="detail-section">
                <h3>Business Hours</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>
                        Monday:
                    </th>
                      <td>
                        08:00 AM    -    05:00 PM
                    </td>
                    </tr>
                    <tr>
                      <th>
                        Tuesday:
                    </th>
                      <td>
                        08:00 AM    -    05:00 PM
                    </td>
                    </tr>
                    <tr>
                      <th>
                        Wednesday:
                    </th>
                      <td>
                        08:00 AM    -    05:00 PM
                    </td>
                    </tr>
                    <tr>
                      <th>
                        Thursday:
                    </th>
                      <td>
                        08:00 AM    -    05:00 PM
                    </td>
                    </tr>
                    <tr>
                      <th>
                        Friday:
                    </th>
                      <td>
                        08:00 AM    -    05:00 PM
                    </td>
                    </tr>
                    <tr>
                      <th>
                        Saturday:
                    </th>
                      <td>
                        Closed
                    </td>
                    </tr>
                    <tr>
                      <th>
                        Sunday:
                    </th>
                      <td>
                        Closed
                    </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="review-block">
              <h2>Reviews</h2>
              <div className="rating-wrapper">
                <span className="rating-number">{calculateRating(3)}</span>
                <div className="rating-stars-container">
                  <span className="rating-stars rating-stars-off">★★★★★</span>
                  <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(3)}%` }}>★★★★★</span>
                </div>
                <span className="rating-count">(12)</span>
              </div>
              <div className="review-overview">
                {[...Array(5)].map((x, i) => (
                  <div className="stat" key={i}>
                    <div className="stat-info">
                      <span className="title">
                        Value for money
                      </span>
                      <strong>
                        {i}
                      </strong>
                    </div>
                    <div className="stat-line">
                      <span style={{ width: `${calculateRating(i)}%` }}></span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="review-list">
                {[...Array(3)].map((x, i) => (
                  <div className="review" key={i}>
                    <div className="review-details">
                      <img src="https://via.placeholder.com/64x36" />
                      <div>
                        <h3>Kelly</h3>
                        <span>2011 Grey Subaru Forester</span>
                      </div>
                    </div>
                    <div className="rating-wrapper">
                      <div className="rating-stars-container">
                        <span className="rating-stars rating-stars-off">★★★★★</span>
                        <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(3)}%` }}>★★★★★</span>
                      </div>
                    </div>
                    <div className="description">
                      Beyond words at the quality of the repair for the price. Two dents and the door now looks as new. Can't even tell there were dings. I did not expect the result at all considering the price was a fifth that others were quoting. Friendly staff. Very thankful to all :)
                    </div>
                    <div className="date">
                      November 11, 2019
                    </div>
                  </div>
                ))}

                <span className="all-reviews-link">Read all reviews</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SelectionDate showSelectDateModel={showSelectDateModel} setSelectDateModel={setSelectDateModel} />
    </Layout>
  );
};

export default QuoteIndexPage;
